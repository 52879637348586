// StoryGraphView.jsx
import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    ReactFlow,
    ReactFlowProvider,
    addEdge,
    applyNodeChanges,
    applyEdgeChanges,
    Background,
    MarkerType,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import { v4 as uuidv4 } from 'uuid';
import {
    DefaultNode,
    GroupNode,
    StartNode,
    EndNode,
} from './CustomNodes';

import AddNodeForm from './AddNodeForm'; // If you still want your form

// Node types
const nodeTypes = {
    default: DefaultNode,
    group: GroupNode,
    start: StartNode,
    end: EndNode,
};

// Mock initial data (all levels)
function generateInitialData() {
    // At the top level, we have some PlotLines
    const plotLine1 = {
        id: uuidv4(),
        type: 'group', // We'll treat PlotLine as a group
        position: { x: 100, y: 100 },
        data: {
            label: 'Main Plot Line',
            nodeType: 'PlotLine',
        },
    };
    const plotLine2 = {
        id: uuidv4(),
        type: 'group',
        position: { x: 500, y: 100 },
        data: {
            label: 'Evil Overlord Quest',
            nodeType: 'PlotLine',
        },
    };

    // Each plot line has a Start + End
    const startNode1 = {
        id: uuidv4(),
        type: 'start',
        position: { x: plotLine1.position.x - 150, y: plotLine1.position.y + 40 },
        data: { label: 'Start' },
    };
    const endNode1 = {
        id: uuidv4(),
        type: 'end',
        position: { x: plotLine1.position.x + 300, y: plotLine1.position.y + 40 },
        data: { label: 'End' },
    };

    const startNode2 = {
        id: uuidv4(),
        type: 'start',
        position: { x: plotLine2.position.x - 150, y: plotLine2.position.y + 40 },
        data: { label: 'Start' },
    };
    const endNode2 = {
        id: uuidv4(),
        type: 'end',
        position: { x: plotLine2.position.x + 300, y: plotLine2.position.y + 40 },
        data: { label: 'End' },
    };

    // Some PlotEvents for each PlotLine (2 for demonstration)
    const event1A = {
        id: uuidv4(),
        type: 'group', // We'll treat PlotEvent as a "group" as well
        parent: plotLine1.id,
        position: { x: 50, y: 60 },
        data: { label: 'Event: Meet Mentor', nodeType: 'PlotEvent' },
    };
    const event1B = {
        id: uuidv4(),
        type: 'group',
        parent: plotLine1.id,
        position: { x: 50, y: 140 },
        data: { label: 'Event: Village Attack', nodeType: 'PlotEvent' },
    };

    // Some PlotElements inside each PlotEvent
    const element1A1 = {
        id: uuidv4(),
        type: 'default', // PlotElement is DefaultNode
        parent: event1A.id,
        position: { x: 20, y: 40 },
        data: { label: 'Element: Dialogue', nodeType: 'PlotElement' },
    };
    const element1A2 = {
        id: uuidv4(),
        type: 'default',
        parent: event1A.id,
        position: { x: 180, y: 40 },
        data: { label: 'Element: Hidden Clue', nodeType: 'PlotElement' },
    };
    const element1B1 = {
        id: uuidv4(),
        type: 'default',
        parent: event1B.id,
        position: { x: 20, y: 40 },
        data: { label: 'Element: Fire Attack', nodeType: 'PlotElement' },
    };

    // Edges
    const edges = [
        // Connect each start to its plotline
        {
            id: uuidv4(),
            source: startNode1.id,
            sourceHandle: 'output',
            target: plotLine1.id,
            targetHandle: 'input',
            type: 'default',
            markerEnd: { type: MarkerType.ArrowClosed },
        },
        {
            id: uuidv4(),
            source: startNode2.id,
            sourceHandle: 'output',
            target: plotLine2.id,
            targetHandle: 'input',
            type: 'default',
            markerEnd: { type: MarkerType.ArrowClosed },
        },
        // Connect plotline to end
        {
            id: uuidv4(),
            source: plotLine1.id,
            sourceHandle: 'output',
            target: endNode1.id,
            targetHandle: 'input',
            type: 'default',
            markerEnd: { type: MarkerType.ArrowClosed },
        },
        {
            id: uuidv4(),
            source: plotLine2.id,
            sourceHandle: 'output',
            target: endNode2.id,
            targetHandle: 'input',
            type: 'default',
            markerEnd: { type: MarkerType.ArrowClosed },
        },
    ];

    const nodes = [
        plotLine1, plotLine2,
        startNode1, endNode1,
        startNode2, endNode2,
        event1A, event1B,
        element1A1, element1A2,
        element1B1,
    ];

    return { nodes, edges };
}

function StoryGraphView() {
    const reactFlowWrapper = useRef(null);

    // Zoom/scale
    const [zoom, setZoom] = useState(1);

    // Master data
    const [{ nodes, edges }, setData] = useState({ nodes: [], edges: [] });

    // Load data on mount
    useEffect(() => {
        const { nodes: initialNodes, edges: initialEdges } = generateInitialData();
        setData({ nodes: initialNodes, edges: initialEdges });
    }, []);

    // Handlers to update the flow
    const onNodesChange = useCallback(
        (changes) => {
            setData((prev) => ({
                ...prev,
                nodes: applyNodeChanges(changes, prev.nodes),
            }));
        },
        []
    );

    const onEdgesChange = useCallback(
        (changes) => {
            setData((prev) => ({
                ...prev,
                edges: applyEdgeChanges(changes, prev.edges),
            }));
        },
        []
    );

    const onConnect = useCallback(
        (params) => {
            const newEdge = {
                ...params,
                id: uuidv4(),
                type: 'default',
                markerEnd: { type: MarkerType.ArrowClosed },
            };
            setData((prev) => ({ ...prev, edges: addEdge(newEdge, prev.edges) }));
        },
        []
    );

    // Detect zoom changes
    const onMove = useCallback((event, viewportOrState) => {
        if (!viewportOrState) return;

        // If you’re on React Flow 10+:
        if (typeof viewportOrState.zoom === 'number') {
            setZoom(viewportOrState.zoom);
        }

        // If you’re on older versions:
        else if (Array.isArray(viewportOrState.transform)) {
            const [ , , zoom] = viewportOrState.transform;
            setZoom(zoom);
        }
    }, []);


    /**
     * Decide which nodes to show at which zoom level.
     * Below is an example of how we might group or filter based on `zoom`.
     * You can tweak thresholds to your liking.
     */
    const getVisibleNodes = useCallback(() => {
        const level = getZoomLevel(zoom);

        // Example logic:
        //   Level 1: Show only PlotLines + their Start/End (hide child events/elements).
        //   Level 2: Show PlotEvents inside PlotLines, but hide PlotElements.
        //   Level 3: Show PlotElements inside PlotEvents.
        //   Level 4: Possibly show some expanded detail or sub-nodes if you have them.

        return nodes.map((node) => {
            const nodeType = node.data?.nodeType;

            // Decide if node is visible at current level:
            let isVisible = false;

            if (level === 1) {
                // Level 1: Show only top-level PlotLines + their Start/End nodes
                //   Condition: nodeType === 'PlotLine' or node.type === 'start'/'end'
                if (
                    nodeType === 'PlotLine' ||
                    node.type === 'start' ||
                    node.type === 'end'
                ) {
                    isVisible = true;
                }
            } else if (level === 2) {
                // Show PlotLines, PlotEvents, plus Start/End
                if (
                    nodeType === 'PlotLine' ||
                    nodeType === 'PlotEvent' ||
                    node.type === 'start' ||
                    node.type === 'end'
                ) {
                    isVisible = true;
                }
            } else if (level === 3) {
                // Show PlotLines, PlotEvents, PlotElements, Start/End
                if (
                    nodeType === 'PlotLine' ||
                    nodeType === 'PlotEvent' ||
                    nodeType === 'PlotElement' ||
                    node.type === 'start' ||
                    node.type === 'end'
                ) {
                    isVisible = true;
                }
            } else {
                // Level 4 (or greater zoom): Show everything
                isVisible = true;
            }

            // Optionally, if not visible, we can return a “hidden” node or skip it.
            if (!isVisible) {
                return {
                    ...node,
                    hidden: true, // React Flow will not render hidden nodes
                };
            }

            return node;
        });
    }, [nodes, zoom]);

    /**
     * Decide which edges to show at each level.
     * Typically, you only show edges if both source and target are visible.
     */
    const getVisibleEdges = useCallback(() => {
        const visibleNodeIds = new Set(getVisibleNodes().filter((n) => !n.hidden).map((n) => n.id));
        return edges.map((edge) => {
            if (visibleNodeIds.has(edge.source) && visibleNodeIds.has(edge.target)) {
                return edge;
            }
            return { ...edge, hidden: true };
        });
    }, [edges, getVisibleNodes]);

    // Utility to map actual zoom => an integer "level"
    const getZoomLevel = (scale) => {
        if (scale < 0.75) return 1;
        if (scale < 1.25) return 2;
        if (scale < 1.75) return 3;
        return 4;
    };

    const visibleNodes = getVisibleNodes();
    const visibleEdges = getVisibleEdges();

    // Example: Add Node callback (from a form or button)
    const handleAddNode = useCallback((label) => {
        // Example: always add a PlotElement at random position
        const newNode = {
            id: uuidv4(),
            type: 'default', // or group, etc.
            position: {
                x: 200,
                y: 300,
            },
            data: {
                label,
                nodeType: 'PlotElement',
            },
        };
        setData((prev) => ({ ...prev, nodes: [...prev.nodes, newNode] }));
    }, []);

    // Example: Delete Node
    const handleDeleteNode = useCallback(
        (nodeId) => {
            setData((prev) => {
                const filteredNodes = prev.nodes.filter((n) => n.id !== nodeId);
                const filteredEdges = prev.edges.filter(
                    (e) => e.source !== nodeId && e.target !== nodeId
                );
                return { nodes: filteredNodes, edges: filteredEdges };
            });
        },
        []
    );

    // Wrap the data with any props needed for your custom nodes
    const finalNodes = visibleNodes.map((node) => {
        return {
            ...node,
            data: {
                ...node.data,
                onDelete: () => handleDeleteNode(node.id),
                // If you want to nest children for group nodes,
                // you can do so by filtering for child nodes (same parent) and injecting them
                children: node.type === 'group'
                    ? getChildrenNodes(node.id, visibleNodes, handleDeleteNode)
                    : null,
            },
        };
    });

    // Helper to get child nodes for grouping
    function getChildrenNodes(parentId, allNodes, onDelete) {
        return allNodes
            .filter((n) => n.parent === parentId && !n.hidden)
            .map((child) => {
                return React.createElement(nodeTypes[child.type] || DefaultNode, {
                    id: child.id,
                    key: child.id,
                    data: {
                        ...child.data,
                        onDelete: () => onDelete(child.id),
                    },
                });
            });
    }

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {/* SIMPLE EXAMPLE SIDEBAR */}
            <div style={{ padding: 10, borderBottom: '1px solid #ccc' }}>
                <strong>Current Zoom:</strong> {zoom.toFixed(2)}
                <br />
                <strong>Zoom Level:</strong> {getZoomLevel(zoom)}
                <br />
                {/* Example: Add node form */}
                <AddNodeForm handleAddNode={handleAddNode} currentLevel={getZoomLevel(zoom)} />
            </div>

            {/* Flow Area */}
            <div style={{ flex: 1 }} ref={reactFlowWrapper}>
                <ReactFlow
                    nodes={finalNodes}
                    edges={visibleEdges}
                    nodeTypes={nodeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onMove={onMove}
                    fitView
                    fitViewOptions={{ padding: 0.2 }}
                    snapToGrid={true}
                    snapGrid={[15, 15]}
                >
                    <Background />
                </ReactFlow>
            </div>
        </div>
    );
}

export default StoryGraphView;
