import React, { useState } from "react";
import { Sidebar } from "flowbite-react";
import {
    HiMenu,
    HiX,
} from "react-icons/hi";
import { SidebarTheme } from "../../themes/SidebarTheme";
import { FaHome } from "react-icons/fa";
import { RiGlobeFill } from "react-icons/ri";
import { IoBook } from "react-icons/io5";
import { FaNewspaper } from "react-icons/fa6";
import { PiBooksFill } from "react-icons/pi";
import { FaDiscord } from "react-icons/fa6";
import { MdFeedback, MdShoppingCart } from "react-icons/md";
import { FaCreditCard } from "react-icons/fa6";

import { useSession } from "../../context/SessionContext";

const SidebarMenu = ({ children }) => {
    const [isSidebarVisible, setSidebarVisible] = useState(false); // Default to hidden for mobile
    const { session } = useSession();

    // Toggle sidebar visibility
    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    if (!session) return (
        <div className="flex-1 flex flex-col p-1 overflow-auto">
            {/* Mobile Header with Toggle Button */}
            {/*<div className="md:hidden flex items-center justify-between bg-gray-800 p-4 w-full">*/}
            {/*    <button onClick={toggleSidebar} aria-label="Toggle Sidebar">*/}
            {/*        {isSidebarVisible ? <HiX size={24}/> : <HiMenu size={24}/>}*/}
            {/*    </button>*/}
            {/*    <span className="text-white text-lg"></span>*/}
            {/*</div>*/}

            {/* Content Area */}
            <div className="flex-grow">
                {children}
            </div>
        </div>
    );

    return (
        <div className="flex flex-1 overflow-hidden">
            {/* Sidebar */}
            <div
                className={`fixed inset-y-0 left-0 transform ${
                    isSidebarVisible ? "translate-x-0" : "-translate-x-full"
                } transition-transform duration-300 ease-in-out bg-gray-800 z-20 w-48 md:static md:translate-x-0`}
            >
                <Sidebar aria-label="Sidebar example with icons" theme={SidebarTheme}>
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item href="/home" icon={FaHome}>
                                My Home
                            </Sidebar.Item>
                            {session.user.permissionLevel >= 3 && (
                                <Sidebar.Item href="/worlds" icon={RiGlobeFill}>
                                    My Worlds
                                </Sidebar.Item>
                            )}
                            {session.user.permissionLevel >= 2 && (
                                <Sidebar.Item href="/stories" icon={IoBook}>
                                    My Stories
                                </Sidebar.Item>
                            )}
                            <Sidebar.Item href="/account" icon={FaCreditCard}>
                                My Account
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item href="/pricing/boosters" icon={MdShoppingCart}>
                                Get Ink
                            </Sidebar.Item>
                            <Sidebar.Item href="/news" icon={FaNewspaper}>
                                News
                            </Sidebar.Item>
                            <Sidebar.Item href="/guides" icon={PiBooksFill}>
                                Documentation
                            </Sidebar.Item>
                            <Sidebar.Item
                                href="https://discord.gg/CF64D9g84E"
                                icon={FaDiscord}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Discord
                            </Sidebar.Item>
                            <Sidebar.Item
                                href="https://docs.google.com/forms/d/e/1FAIpQLSePYHwRWufedoEKarYJEWHYpWTVN3ClHxGL3BCuqe_xuB3Tfw/viewform?usp=sf_link"
                                icon={MdFeedback}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Feedback
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
            </div>

            {/* Overlay for Mobile when Sidebar is Open */}
            {isSidebarVisible && (
                <div
                    className="fixed inset-0 bg-black opacity-50 z-10 md:hidden"
                    onClick={toggleSidebar}
                ></div>
            )}

            {/* Main Content */}
            <div className="flex-1 flex flex-col overflow-auto">
                {/* Mobile Header with Toggle Button */}
                {/*<div className="md:hidden flex items-center justify-between bg-gray-800 p-4 w-full">*/}
                {/*    <button onClick={toggleSidebar} aria-label="Toggle Sidebar">*/}
                {/*        {isSidebarVisible ? <HiX size={24} /> : <HiMenu size={24} />}*/}
                {/*    </button>*/}
                {/*    <span className="text-white text-lg"></span>*/}
                {/*</div>*/}

                {/* Content Area */}
                <div className="flex-grow">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SidebarMenu;
