// AddNodeForm.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Utility function to determine node type based on level
const getNodeTypeByLevel = (level) => {
    switch (level) {
        case 1:
            return 'PlotNarrative';
        case 2:
            return 'PlotElement';
        default:
            return 'Unknown';
    }
};

const AddNodeForm = ({ handleAddNode, currentLevel }) => {
    const [label, setLabel] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (label.trim() === '') return;
        handleAddNode(label.trim());
        setLabel('');
    };

    return (
        <form onSubmit={handleSubmit} style={{ marginBottom: 10 }}>
            <input
                type="text"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                placeholder={`Add ${getNodeTypeByLevel(currentLevel)}`}
                style={{
                    width: '100%',
                    padding: '5px',
                    marginBottom: '5px',
                    borderRadius: '3px',
                    border: '1px solid #ccc',
                }}
                required
            />
            <button
                type="submit"
                style={{
                    width: '100%',
                    padding: '5px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '3px',
                    cursor: 'pointer',
                }}
            >
                Add
            </button>
        </form>
    );
};

AddNodeForm.propTypes = {
    handleAddNode: PropTypes.func.isRequired,
    currentLevel: PropTypes.number.isRequired,
};

export default AddNodeForm;
