import React, {useState} from "react";
import StoryShelves from "../../components/story/StoryShelves";
import {useSession} from "../../context/SessionContext";
import {Helmet} from "react-helmet";

function MyHomePage() {

    return (
        <div>
            <Helmet>
                <title>My Home</title>
                <meta
                    name="description"
                    content="A list of your favorite stories by other My Adventures users."
                />
            </Helmet>

            <StoryShelves/>
        </div>
    )
}

export default MyHomePage;
