import {Blockquote, Breadcrumb, Button, Card} from "flowbite-react";
import {useSession} from "../../context/SessionContext";
import StoryShelves from "../../components/story/StoryShelves";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";

function DiscoverPage() {
    // const { session, login } = useSession();
    // const navigate = useNavigate();
    //
    // useEffect(() => {
    //     if (session) navigate("/home");
    // }, [session]);

    return (
        <div>
            <Helmet>
                <title>Discover - My Adventures</title>
                <meta
                    name="description"
                    content="Discover new stories and worlds to explore. Adventure awaits!"
                />
            </Helmet>

            <StoryShelves/>
        </div>
    )
}

export default DiscoverPage;
