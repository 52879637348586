// CustomNodes.jsx
import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { Rnd } from 'react-rnd'; // If you need resizable group boxes

const buttonStyle = {
    fontSize: '10px',
    padding: '2px 4px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '3px',
};

/**
 * GROUP NODE
 * Used for:
 * - PlotLine (Level 1 or 2)
 * - PlotEvent (Level 2 or 3)
 * etc.
 */
export const GroupNode = ({ data }) => {
    const { label, onDelete, children } = data;

    return (
        <div
            style={{
                border: '2px solid #555',
                borderRadius: 5,
                backgroundColor: '#f0f0f0',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
                position: 'relative',
                padding: 10,
                overflow: 'auto',
                minWidth: 200,
                minHeight: 100,
            }}
        >
            <strong>{label}</strong>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                }}
                style={{
                    ...buttonStyle,
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    backgroundColor: '#dc3545',
                    color: '#fff',
                }}
            >
                Delete
            </button>
            {/* Render child nodes inside the group if any */}
            <div style={{ marginTop: 30 }}>{children}</div>

            {/* We can have input or output handles if needed */}
            <Handle
                type="target"
                position={Position.Left}
                id="input"
                style={{ background: '#333', top: '50%' }}
            />
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                style={{ background: '#333', top: '50%' }}
            />
        </div>
    );
};

/**
 * DEFAULT NODE (Used for PlotElement or other small node types)
 */
export const DefaultNode = ({ data }) => {
    const { label, onDelete, nodeType } = data;
    return (
        <div
            style={{
                padding: 10,
                border: '1px solid #888',
                borderRadius: 5,
                backgroundColor: '#fff',
                minWidth: 120,
                position: 'relative',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
            }}
        >
            {/* Input Handle */}
            <Handle
                type="target"
                position={Position.Left}
                id="input"
                style={{ background: '#555' }}
            />
            <strong>{label}</strong>
            <div style={{ fontSize: 12, marginTop: 4, color: '#444' }}>
                Type: {nodeType}
            </div>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                }}
                style={{ ...buttonStyle, backgroundColor: '#dc3545', color: '#fff', marginTop: 4 }}
            >
                Delete
            </button>
            {/* Output Handle */}
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                style={{ top: '50%', background: '#555' }}
            />
        </div>
    );
};

/**
 * START NODE
 */
export const StartNode = ({ data }) => {
    const { label, onDelete } = data;
    return (
        <div
            style={{
                padding: 10,
                border: '2px solid green',
                borderRadius: 5,
                backgroundColor: '#e0ffe0',
                minWidth: 120,
                position: 'relative',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
            }}
        >
            <strong>{label}</strong>
            <div style={{ marginTop: 4 }}>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    style={{ ...buttonStyle, backgroundColor: '#dc3545', color: '#fff' }}
                >
                    Delete
                </button>
            </div>
            {/* Single Output */}
            <Handle
                type="source"
                position={Position.Right}
                id="output"
                style={{ top: '50%', background: 'green' }}
            />
        </div>
    );
};

/**
 * END NODE
 */
export const EndNode = ({ data }) => {
    const { label, onDelete } = data;
    return (
        <div
            style={{
                padding: 10,
                border: '2px solid red',
                borderRadius: 5,
                backgroundColor: '#ffe0e0',
                minWidth: 120,
                position: 'relative',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
            }}
        >
            {/* Single Input */}
            <Handle
                type="target"
                position={Position.Left}
                id="input"
                style={{ background: 'red' }}
            />
            <strong>{label}</strong>
            <div style={{ marginTop: 4 }}>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                    style={{ ...buttonStyle, backgroundColor: '#dc3545', color: '#fff' }}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};
