// src/components/news/AddEditNewsModal.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import axios from 'axios';
import {
    Modal,
    Button,
    TextInput,
    Textarea,
    Label,
    Checkbox,
} from 'flowbite-react';
import { GiQuillInk } from "react-icons/gi";
import { IoNewspaper } from "react-icons/io5";
import Select from "react-select";
import { TextAreaTheme } from "../../../themes/TextAreaTheme";
import { ToastContainer, toast } from 'react-toastify'; // For notifications
import 'react-toastify/dist/ReactToastify.css';
import useSelectThemeStyle from "../../../themes/SelectTheme";

const AddEditNewsModal = ({
                              isOpen,
                              onClose,
                              articleToEdit,
                              onSuccess,
                              session,
                              api
                          }) => {
    const [name, setName] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [published, setPublished] = useState(false);
    const [description, setDescription] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [category, setCategory] = useState(null); // Changed to null for react-select
    const [tags, setTags] = useState([]);
    const [segments, setSegments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [expandedSegmentId, setExpandedSegmentId] = useState(null); // Track which segment is expanded

    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    useEffect(() => {
        if (articleToEdit) {
            setName(articleToEdit.name || '');
            setDescription(articleToEdit.description || '');
            setImgUrl(articleToEdit.imgUrl || '');
            setCategory(articleToEdit.category ? { value: articleToEdit.category, label: capitalizeFirstLetter(articleToEdit.category) } : null);
            setTags(articleToEdit.tags || []);
            setPublishDate(formatDate(articleToEdit.publishedAt || articleToEdit.createdAt || Date.now()));
            setPublished(articleToEdit.published || false);
            // Initialize segments with existing _id
            setSegments(
                (articleToEdit.segments || []).map(segment => ({
                    _id: segment._id, // Use _id from backend
                    title: segment.title,
                    content: segment.content,
                    by: segment.by,
                }))
            );
        } else {
            setName('');
            setDescription('');
            setImgUrl('');
            setCategory(null);
            setTags([]);
            setSegments([]);
            setPublished(false);
            setPublishDate(formatDate(Date.now()));
        }
        setError(''); // Clear errors when modal opens/closes
        setExpandedSegmentId(null); // Reset expanded segment when modal opens/closes
    }, [articleToEdit, isOpen]);

    const toggleExpand = (id) => {
        setExpandedSegmentId(prev => (prev === id ? null : id));
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleSegmentChange = (id, field, value) => {
        setSegments(prevSegments =>
            prevSegments.map(segment =>
                segment._id === id ? { ...segment, [field]: value } : segment
            )
        );
    };

    const addSegment = () => {
        const tempId = `temp-${Date.now()}`; // Temporary ID based on timestamp
        setSegments(prevSegments => [
            ...prevSegments,
            { _id: tempId, title: '', content: '', by: '' } // Initialize new segment with temporary ID
        ]);
    };

    const removeSegment = (id) => {
        setSegments(prevSegments => prevSegments.filter(segment => segment._id !== id));
        if (expandedSegmentId === id) {
            setExpandedSegmentId(null); // Collapse if the expanded segment is removed
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            // Validate segments
            for (let i = 0; i < segments.length; i++) {
                const seg = segments[i];
                if (!seg.title.trim() || !seg.content.trim()) {
                    setError(`Segment ${i + 1} requires both title and content.`);
                    setLoading(false);
                    return;
                }
                // if (!seg.by.trim()) {
                //     setError(`Segment ${i + 1} requires the author.`);
                //     setLoading(false);
                //     return;
                // }
            }

            // Validate other fields if necessary
            if (!name.trim()) {
                setError('Article name is required.');
                setLoading(false);
                return;
            }

            if (!description.trim()) {
                setError('Article description is required.');
                setLoading(false);
                return;
            }

            if (!imgUrl.trim()) {
                setError('Image URL is required.');
                setLoading(false);
                return;
            }

            if (!category) {
                setError('Category is required.');
                setLoading(false);
                return;
            }

            // if (tags.length === 0) {
            //     setError('At least one tag is required.');
            //     setLoading(false);
            //     return;
            // }

            const data = {
                name,
                description,
                imgUrl,
                category: category.value,
                tags,
                published,
                publishedAt: publishDate,
                segments: segments.map(seg => ({
                    // Include _id only if it's not a temporary ID
                    ...(seg._id && !seg._id.startsWith('temp-') && { _id: seg._id }),
                    title: seg.title,
                    content: seg.content,
                    by: seg.by,
                })),
            };

            if (articleToEdit) {
                // Update existing article
                console.log(data);
                await api.put(`/news/${articleToEdit._id}`, data);
                toast.success('Article updated successfully!');
            } else {
                // Create new article
                await api.post(`/news`, data);
                toast.success('Article created successfully!');
            }

            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving news article:', err);
            const message = err.response?.data?.message || 'Failed to save news article.';
            setError(message);
            toast.error(message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!articleToEdit) return;
        if (!window.confirm('Are you sure you want to delete this news article?')) return;

        setLoading(true);
        setError('');
        try {
            await api.delete(`/news/${articleToEdit._id}`);
            toast.success('Article deleted successfully!');
            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting news article:', err);
            const message = err.response?.data?.message || 'Failed to delete news article.';
            setError(message);
            toast.error(message);
        } finally {
            setLoading(false);
        }
    };

    const tagOptions = [
        { value: 'breaking', label: 'Breaking' },
        { value: 'announcement', label: 'Announcement' },
        { value: 'update', label: 'Update' },
        // Add more predefined tags if necessary
    ];

    const categoryOptions = [
        { value: 'sports', label: 'Sports' },
        { value: 'politics', label: 'Politics' },
        { value: 'entertainment', label: 'Entertainment' },
        { value: 'devblog', label: 'Dev Blog' },
        // Add more categories as needed
    ];

    const handleTagChange = (selectedOptions) => {
        setTags(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const handleCategoryChange = (selectedOption) => {
        setCategory(selectedOption);
    };

    return (
        <>
            <Modal
                show={isOpen}
                onClose={onClose}
                size="4xl"
            >
                <Modal.Header>
                    <div className="flex justify-between items-center w-full space-x-2">
                        <IoNewspaper size={24} />
                        <span className="text-2xl font-bold">
                            {articleToEdit ? 'Edit News Article' : 'Add News Article'}
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {error && (
                        <div className="mb-4">
                            <Label color="failure" value={error} />
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                        {/* Name Field */}
                        <div>
                            <Label htmlFor="name" value="Name" />
                            <TextInput
                                id="name"
                                type="text"
                                placeholder="Enter article name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>
                        {/* Description Field */}
                        <div>
                            <Label htmlFor="description" value="Description" />
                            <Textarea
                                theme={TextAreaTheme}
                                id="description"
                                placeholder="Enter article description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                rows={2}
                                disabled={loading}
                            />
                        </div>
                        {/* Published Date Field */}
                        <div>
                            <Label htmlFor="date" value="Published Date" />
                            <TextInput
                                id="date"
                                type="date"
                                value={publishDate}
                                onChange={(e) => setPublishDate(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>
                        {/* Published Checkbox */}
                        <div className="flex items-center">
                            <Checkbox
                                id="published"
                                checked={published}
                                onChange={(e) => setPublished(e.target.checked)}
                                disabled={loading}
                            />
                            <Label className="pl-2" htmlFor="published" value="Is Published?" />
                        </div>
                        {/* Image URL Field */}
                        <div>
                            <Label htmlFor="imgUrl" value="Image URL" />
                            <TextInput
                                id="imgUrl"
                                type="url"
                                placeholder="Enter image URL"
                                value={imgUrl}
                                onChange={(e) => setImgUrl(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>
                        {/* Category Field */}
                        <div>
                            <Label htmlFor="category" value="Category" />
                            <Select
                                id="category"
                                styles={useSelectThemeStyle()}
                                options={categoryOptions}
                                onChange={handleCategoryChange}
                                value={category}
                                placeholder="Select a category..."
                                isClearable
                                required
                                isDisabled={loading}
                            />
                        </div>
                        {/* Tags Field */}
                        <div>
                            <Label htmlFor="tags" value="Tags" />
                            <Select
                                id="tags"
                                styles={useSelectThemeStyle()}
                                options={tagOptions}
                                isMulti
                                onChange={handleTagChange}
                                value={tagOptions.filter(option => tags.includes(option.value))}
                                placeholder="Select tags..."
                                isClearable
                                isDisabled={loading}
                            />
                        </div>
                        {/* Segments Section */}
                        <div className="border-t pt-4">
                            <div className="flex justify-between items-center mb-2">
                                <Label value="Segments" />
                                <Button
                                    type="button"
                                    onClick={addSegment}
                                    color="green"
                                    size="xs"
                                    disabled={loading}
                                >
                                    Add Segment
                                </Button>
                            </div>
                            {segments.length === 0 && (
                                <p className="text-gray-500">No segments added yet.</p>
                            )}
                            {segments.map((segment, index) => (
                                <div key={segment._id} className="mb-4 p-4 border rounded relative">
                                    <div className="flex justify-between items-center mb-2">
                                        <span className="font-semibold text-gray-700 dark:text-gray-200">Segment {index + 1}</span>
                                        <Button
                                            type="button"
                                            onClick={() => removeSegment(segment._id)}
                                            color="failure"
                                            size="xs"
                                            disabled={loading}
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                    {/* Segment Title */}
                                    <div className="mb-2">
                                        <Label htmlFor={`segment-title-${segment._id}`} value="Title" />
                                        <TextInput
                                            id={`segment-title-${segment._id}`}
                                            type="text"
                                            placeholder="Enter segment title"
                                            value={segment.title}
                                            onChange={(e) => handleSegmentChange(segment._id, 'title', e.target.value)}
                                            required
                                            disabled={loading}
                                        />
                                    </div>
                                    {/* Segment Author */}
                                    <div className="mb-2">
                                        <Label htmlFor={`segment-author-${segment._id}`} value="Author" />
                                        <TextInput
                                            id={`segment-author-${segment._id}`}
                                            type="text"
                                            placeholder="Enter author"
                                            value={segment.by}
                                            onChange={(e) => handleSegmentChange(segment._id, 'by', e.target.value)}
                                            disabled={loading}
                                        />
                                    </div>
                                    {/* Segment Content */}
                                    <div className={`mb-2 transition-all duration-300 ${expandedSegmentId === segment._id ? 'max-h-full' : 'max-h-40 overflow-hidden'}`}>
                                        <Label htmlFor={`segment-content-${segment._id}`} value="Content" />
                                        <Textarea
                                            theme={TextAreaTheme}
                                            id={`segment-content-${segment._id}`}
                                            placeholder="Enter segment content"
                                            value={segment.content}
                                            onChange={(e) => handleSegmentChange(segment._id, 'content', e.target.value)}
                                            required
                                            rows={expandedSegmentId === segment._id ? 14 : 4} // Increase rows when expanded
                                            disabled={loading}
                                        />
                                    </div>
                                    {/* Expand/Collapse Button */}
                                    <button
                                        type="button"
                                        className="absolute top-1 left-2 text-xs text-blue-500 underline"
                                        onClick={() => toggleExpand(segment._id)}
                                        disabled={loading}
                                    >
                                        {expandedSegmentId === segment._id ? 'Shrink' : 'Expand'}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex justify-between w-full">
                        <div className="flex space-x-2">
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                disabled={loading}
                                color="blue"
                            >
                                {loading ? 'Saving...' : articleToEdit ? 'Save Changes' : 'Add Article'}
                            </Button>
                            {articleToEdit && (
                                <Button
                                    color="failure"
                                    onClick={handleDelete}
                                    disabled={loading}
                                >
                                    Delete
                                </Button>
                            )}
                        </div>
                        <Button
                            color="gray"
                            onClick={onClose}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}
AddEditNewsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    articleToEdit: PropTypes.object, // Pass the article object when editing
    onSuccess: PropTypes.func.isRequired, // Callback after successful add/edit/delete
    session: PropTypes.shape({
        token: PropTypes.string.isRequired,
        user: PropTypes.object
    }).isRequired, // Assuming session has a token and user object
};

AddEditNewsModal.defaultProps = {
    articleToEdit: null,
};

export default AddEditNewsModal;
