import React, { useEffect, useState } from 'react';
import { Card, Badge } from 'flowbite-react';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet';

export default function NewsPage() {
    const navigate = useNavigate();
    const [newsArticles, setNewsArticles] = useState([]);

    const fetchNews = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/list`);
            setNewsArticles(response.data);
        } catch (error) {
            console.error('Failed to fetch news articles:', error);
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }


    useEffect(() => {
        fetchNews();
    }, []);



    return (
        <>
            <Helmet>
                <title>Latest News - My Adventures</title>
                <meta
                    name="description"
                    content="Stay updated with the latest news articles, devblogs and updates."
                />
            </Helmet>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-8 mx-auto max-w-7xl">
                {newsArticles.map((article, index) => (
                    <Card key={index} theme={cardTheme} className="cursor-pointer flex flex-col dark:bg-gray-900 dark:border-0"
                          onClick={() => navigate(`/news/article/${article._id}`)}>
                        <img src={article.imgUrl} alt={article.name} className="w-full rounded-t-lg"/>
                        <div className="p-2 flex-grow">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-300">
                                {article.name}
                            </h5>
                            <p className="text-md text-gray-500">{formatDate(article.publishedAt || article.createdAt)}</p>
                            <div className="flex flex-wrap gap-2">
                                <Badge color="info">{article.category}</Badge>
                            </div>
                            <div className="pt-4">
                                <p className="font-normal text-gray-700 dark:text-gray-400">{article.description}</p>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </>
    );
}


const cardTheme = {
    "root": {
        "base": "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800",
        "children": "flex h-full flex-col justify-center gap-4",
        "horizontal": {
            "off": "flex-col",
            "on": "flex-col md:max-w-xl md:flex-row"
        },
        "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
    },
    "img": {
        "base": "",
        "horizontal": {
            "off": "rounded-t-lg",
            "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        }
    }
}