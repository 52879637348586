import {Footer, FooterCopyright, FooterLink, FooterLinkGroup} from "flowbite-react";
import {FooterTheme} from "../../themes/FooterTheme";
import {useLocation} from "react-router-dom";
import React from "react";
import { FaDiscord, FaCookieBite, FaScroll } from "react-icons/fa";
import { MdPrivacyTip } from "react-icons/md";
import { FaXTwitter, FaBluesky } from "react-icons/fa6";
import { GrReddit } from "react-icons/gr";

const SiteFooter = () => {

    const location = useLocation();  // Now inside Router

    // Define a list of paths where the footer should not be displayed
    const noFooterRoutes = ["/test", "/adventure"];

    // Check if the current path matches any in the list of routes without a footer
    const shouldShowFooter = !noFooterRoutes.some(route => location.pathname.startsWith(route));

    if (!shouldShowFooter) return null;

    return (
        <Footer
            theme={FooterTheme}
            className="fixed bottom-0 left-0 right-0 z-50 bg-gray-100 dark:bg-gray-800 flex items-center justify-between px-4 py-2 md:px-6"
        >
            {/* Footer Copyright */}
            <FooterCopyright
                href="#"
                by="Ephor Software Ltd"
                year={2024}
                className="text-xs sm:text-sm whitespace-nowrap" // Prevent wrapping
            />

            {/* Footer Links */}
            <FooterLinkGroup className="flex text-sm font-light items-center gap-2.5 sm:gap-3 md:gap-4 lg:gap-6 dark:text-gray-400">
                <FooterLink href="/privacypolicy.html" title={"Privacy Policy"}>
                    <div className="flex items-center gap-1 ">
                        <MdPrivacyTip size={16} />
                        <span className="hidden lg:block">Privacy Policy</span>
                    </div>
                </FooterLink>
                <FooterLink href="/termsofservice.html" title={"Terms of Service"}>
                    <div className="flex items-center gap-1">
                        <FaScroll size={16} />
                        <span className="hidden lg:block">Terms of Service</span>
                    </div>
                </FooterLink>
                <FooterLink href="/cookiepolicy.html" title={"Cookie Policy"}>
                    <div className="flex items-center gap-1">
                        <FaCookieBite size={16} />
                        <span className="hidden lg:block">Cookie Policy</span>
                    </div>
                </FooterLink>
                <FooterLink href="https://discord.gg/CF64D9g84E" title={"Discord"}>
                    <div className="flex items-center gap-1">
                        <FaDiscord size={18} />
                        <span className="hidden xl:block">Discord</span>
                    </div>
                </FooterLink>
                <FooterLink href="https://x.com/MyAdventures_ai" title={"Twitter"}>
                    <div className="flex items-center gap-1">
                        <FaXTwitter size={16} />
                        <span className="hidden xl:block">Twitter</span>
                    </div>
                </FooterLink>
                <FooterLink href="https://bsky.app/profile/myadventuresai.bsky.social" title={"Blue Sky"}>
                    <div className="flex items-center gap-1">
                        <FaBluesky size={16} />
                        <span className="hidden xl:block">Blue Sky</span>
                    </div>
                </FooterLink>
                <FooterLink href="https://www.reddit.com/r/MyAdventures/" title={"Reddit"}>
                    <div className="flex items-center gap-1">
                        <GrReddit size={18} className="-translate-y-0.5" />
                        <span className="hidden xl:block">Reddit</span>
                    </div>
                </FooterLink>
            </FooterLinkGroup>
        </Footer>
    );
};

export default SiteFooter;