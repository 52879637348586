import React, {Component, useContext} from 'react';
import { Routes, Route, Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Sidebar } from 'flowbite-react';
import { FaBook, FaChevronRight } from "react-icons/fa";
import DarkModeContext from "../../context/DarkModeContext";
import {Helmet} from "react-helmet";
//import remark-toc //— generate a table of contents

// Define the paths to the markdown files
const guideList = {
    "Community": '/guides/community.md',
    "Overview": '/guides/overview.md',
    "Worlds": '/guides/worlds.md',
    "Lore": '/guides/lore.md',
    "Stories": '/guides/stories.md',
    "Plot": '/guides/plotElements.md',
    "Characters": '/guides/characters.md',
    "Adventures": '/guides/adventures.md',
};

class Guides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            headings: [],
            sidebarOpen: false,  // State to control sidebar visibility
        };
    }

    extractHeadings(markdown) {
        const regex = /^##\s(.+)$/gm;
        let match;
        const headings = [];
        while ((match = regex.exec(markdown)) !== null) {
            headings.push({ text: match[1], id: match[1].toLowerCase().replace(/\s+/g, '-') });
        }
        return headings;
    }

    getFilteredContent() {
        const { content, searchQuery } = this.state;
        if (!searchQuery) return content;

        const regex = new RegExp(searchQuery, 'gi');
        return content.replace(regex, (match) => `<mark>${match}</mark>`);
    }

    loadGuide = (guidePath) => {
        fetch(guidePath)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Failed to load ${guidePath}: ${response.statusText}`);
                }
                return response.text();
            })
            .then((text) => {
                this.setState({ content: text, headings: this.extractHeadings(text) });
            })
            .catch((error) => {
                console.error('Error loading guide:', error);
                this.setState({ content: 'Failed to load guide content.' });
            });
    };

    componentDidMount() {
        const { guideName } = this.props;
        if (guideName) {
            this.loadGuide(guideList[guideName]);
        } else {
            this.setState({ content: '# Please select a guide from the sidebar.' });
        }
    }

    componentDidUpdate(prevProps) {
        const { guideName } = this.props;
        if (guideName !== prevProps.guideName) {
            this.loadGuide(guideList[guideName]);
        }
    }

    toggleSidebar = () => {
        this.setState((prevState) => ({ sidebarOpen: !prevState.sidebarOpen }));
    };

    render() {
        const { content, sidebarOpen } = this.state;
        const { darkMode } = this.props;  // Add this line
        const filteredContent = this.getFilteredContent();

        return (
            <div className="flex h-screen font-raleway">  {/* Ensures the container takes up the full height */}
                <Helmet>
                    <title>Guides - My Adventures</title>
                    <meta
                        name="description"
                        content="Refrence Documentation and how-to guides for My Adventures"
                    />
                </Helmet>
                {/* Sidebar */}
                <div className="fixed w-44 overflow-auto">
                    <Sidebar theme={sidebarTheme}>
                        <Sidebar.Items>
                            <Sidebar.ItemGroup>
                                {Object.keys(guideList).map((name) => (
                                    <Sidebar.Item
                                        as={Link}
                                        to={`/guide/${name}`}
                                        key={name}
                                        icon={FaBook}
                                        onClick={() => this.setState({ sidebarOpen: false })}  // Close sidebar on link click in mobile
                                    >
                                        {name}
                                    </Sidebar.Item>
                                ))}
                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </Sidebar>
                </div>


                {/* Main Content Area */}
                <div className="flex-1 ml-44 pl-6 pr-6">
                    {/* New Chevron Icon for Mobile Toggle */}
                    {/*<div className="md:hidden flex justify-between items-center">*/}
                    {/*    <button onClick={this.toggleSidebar} className="text-gray-500 focus:outline-none flex items-center">*/}
                    {/*        <FaChevronRight size={24} />*/}
                    {/*        <span className="ml-2">Menu</span>*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    <div className="content markdown ">
                        <ReactMarkdown
                            className="prose"
                            children={filteredContent}
                            remarkPlugins={[remarkGfm]} // Add the plugin here
                            urlTransform={uri => {
                                if (uri.startsWith("http") || uri.startsWith("/")) {
                                    return uri;
                                }

                                const cleanedUri = uri.replace(/^\.\.\//, '');
                                return `/images/${cleanedUri}`;
                            }}
                            components={{
                                h1: ({node, ...props}) => (<h1 style={{ color: darkMode ? '#bbbbbb' : '#444444' }} {...props} />),
                                h2: ({node, ...props}) => (<h2 style={{ color: darkMode ? '#bbbbbb' : '#444444' }} {...props} />),
                                h3: ({node, ...props}) => (<h3 style={{ color: darkMode ? '#bbbbbb' : '#444444' }} {...props} />),
                                strong: ({node, ...props}) => (<strong style={{ color: darkMode ? '#bbbbbb' : '#444444' }} {...props} />),
                                p: ({node, ...props}) => <p style={{marginBottom: '1em'}} {...props} />,
                                img: ({node, ...props}) => <img style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                }} {...props} />,
                                blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600 dark:text-gray-400" {...props} />, // Custom blockquote style
                                ul: ({node, ...props}) => <ul className="list-disc list-inside ml-6" {...props} />, // Style for unordered lists
                                ol: ({node, ...props}) => <ol className="list-decimal list-inside ml-6" {...props} />, // Style for ordered lists
                            }}
                        >
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        );
    }
}

export default function GuidesPage() {
    const { darkMode } = useContext(DarkModeContext);
    const { guideName } = useParams();
    return <Guides guideName={guideName} darkMode={darkMode} />;
}

const sidebarTheme = {
    "root": {
        "base": "h-auto",
        "collapsed": {
            "on": "w-16",
            "off": "w-44"
        },
        "inner": "h-full overflow-y-auto overflow-x-hidden rounded bg-gray-50 px-3 py-4 dark:bg-gray-800"
    },
    "collapse": {
        "button": "group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
        "icon": {
            "base": "h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
            "open": {
                "off": "",
                "on": "text-gray-900"
            }
        },
        "label": {
            "base": "ml-3 flex-1 whitespace-nowrap text-left",
            "icon": {
                "base": "h-6 w-6 transition delay-0 ease-in-out",
                "open": {
                    "on": "rotate-180",
                    "off": ""
                }
            }
        },
        "list": "space-y-2 py-2"
    },
    "cta": {
        "base": "mt-6 rounded-lg bg-gray-100 p-4 dark:bg-gray-700",
        "color": {
            "blue": "bg-cyan-50 dark:bg-cyan-900",
            "dark": "bg-dark-50 dark:bg-dark-900",
            "failure": "bg-red-50 dark:bg-red-900",
            "gray": "bg-alternative-50 dark:bg-alternative-900",
            "green": "bg-green-50 dark:bg-green-900",
            "light": "bg-light-50 dark:bg-light-900",
            "red": "bg-red-50 dark:bg-red-900",
            "purple": "bg-purple-50 dark:bg-purple-900",
            "success": "bg-green-50 dark:bg-green-900",
            "yellow": "bg-yellow-50 dark:bg-yellow-900",
            "warning": "bg-yellow-50 dark:bg-yellow-900"
        }
    },
    "item": {
        "base": "flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
        "active": "bg-gray-100 dark:bg-gray-700",
        "collapsed": {
            "insideCollapse": "group w-full pl-8 transition duration-75",
            "noIcon": "font-bold"
        },
        "content": {
            "base": "flex-1 whitespace-nowrap px-3"
        },
        "icon": {
            "base": "h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
            "active": "text-gray-700 dark:text-gray-100"
        },
        "label": "",
        "listItem": ""
    },
    "items": {
        "base": ""
    },
    "itemGroup": {
        "base": "mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700"
    },
    "logo": {
        "base": "mb-5 flex items-center pl-2.5",
        "collapsed": {
            "on": "hidden",
            "off": "self-center whitespace-nowrap text-xl font-semibold dark:text-white"
        },
        "img": "mr-3 h-6 sm:h-7"
    }
}