import {Banner, BannerCollapseButton, Blockquote, Breadcrumb, Button, Card} from "flowbite-react";
import { HiX } from "react-icons/hi";
import { MdAnnouncement } from "react-icons/md";
import React, {useEffect, useState} from "react";
import {useSession} from "../../context/SessionContext";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";

function LandingPage() {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const { session, login } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        if (session) navigate("/home");
    }, [session]);

    return (
        <div className="relative w-full h-full overflow-hidden flex items-center justify-center">
            <Helmet>
                <meta property="og:title" content="My Adventures: AI Story Generator and Fantasy World Builder" />
                <meta property="og:description" content="Unleash your creativity with My Adventures. Build worlds, craft stories, and design adventures with AI." />
                <meta property="og:image" content="https://storage.googleapis.com/myadventures-images/news/WhatToBuild.webp" />
                <meta property="og:url" content="https://myadventures.com/landing" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* Background Layer */}
            <div
                className={
                    'absolute inset-0 bg-center bg-cover bg-no-repeat opacity-20 blur-sm'
                }
                style={{
                    backgroundImage: 'url(https://storage.googleapis.com/myadventures-images/news/WhatToBuild.webp)',
                }}
            />


            {/* Main content area */}
            <div className="relative p-4 pb-20 flex flex-col items-center space-y-16 font-raleway text-gray-200">
                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4 border-4-blur-md">
                    <h2 className="text-3xl font-bold mb-4">AI Generation, Done Right!</h2>
                    <p className="text-xl font-light mb-4"><strong>My Adventures</strong> empowers users to create and play through their own adventure stories with the assistance of
                        AI-powered NPCs and an "AI Story/Game/Dungeon Master." Unlike existing solutions, My Adventures is constrained by rules,
                        facts, and figures, resulting in fewer hallucinations. It achieves this by storing more than just "memories"; it utilizes a
                        combination of deterministic procedural generation and a rich data history for Retrieval-Augmented Generation (RAG) of each
                        character, location, item, and their associated stat blocks (properties and attributes), ensuring greater story coherency.</p>
                </section>

                <div className="relative w-full md:w-7/8 aspect-video mx-auto">
                    {!isVideoLoaded && (
                        <img
                            src="https://storage.googleapis.com/myadventures-images/WalkIntoCreation.webp"
                            alt="Video preview"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                        />
                    )}
                    <iframe
                        loading="lazy"
                        className={`w-full h-full ${isVideoLoaded ? "relative" : "invisible"}`} // Hide until loaded
                        src="https://www.youtube.com/embed/5r6pzd_joHo?si=5WIhvLnaLeAqYSBw"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        onLoad={() => setIsVideoLoaded(true)} // Detect when video loads
                    />
                </div>

                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4 border-4-blur-md">
                    <h2 className="text-3xl font-bold mb-4">Writing Better, More Structured, and Consistent AI Stories</h2>
                    <p className="text-xl font-light mb-4">A major drawback of current AI-driven adventure stories is their heavy reliance on the underlying LLM doing all the work
                        with a limited context window to maintain narrative coherence. Anyone who has experimented with these systems, including
                        ChatGPT, knows how stories can break down, hallucinate details, struggle with pacing, or become stuck in repetitive loops. You
                        might find yourself either killing the dragon two sentences into the story with no push-back or getting bogged down in the
                        minutiae of how to even start your adventure.</p>

                    <p className="text-xl font-light mb-4">Structured more like an open world RPG game with a dynamic movie script, everything in
                        My Adventures takes place in scenes
                        with defined characters, locations, items, and plot script elements. These can be user-crafted for a more curated
                        experience
                        or generated dynamically, allowing flexibility to deviate from the main storyline. For instance, instead of embarking on a
                        grand adventure, you could choose to go fishing with the local townsfolk if you prefer a more relaxed experience.</p>
                </section>

                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4  border-4-blur-md">
                    <h2 className="text-3xl font-bold mb-4">Craft Your Own Journey: Interactive Fiction at Its Best</h2>
                    <ul className="list-disc list-inside font-light text-xl mb-4">
                        <li><strong>Generate Interactive Stories:</strong> Turn your writing prompts into dynamic, immersive tales with AI.</li>
                        <li><strong>Explore Fantasy and Sci-Fi Universes:</strong> Discover unique quests, magical dungeons, epic battles, and
                            futuristic worlds.
                        </li>
                        <li><strong>Design Role-Playing Scenarios:</strong> Create story-based RPG games inspired by the best campaigns.</li>
                    </ul>
                </section>

                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4  border-4-blur-md">
                    <h2 className="text-3xl font-bold  mb-4">Powered by AI, Fueled by Creativity</h2>
                    <p className="text-xl font-light mb-4">Build endless stories, worlds, and characters—all brought to life with unparalleled depth.
                        Want to
                        create a bustling city of merchants or a sprawling dungeon steeped in history? With AI, you craft the foundation, and we bring
                        it to life.</p>
                </section>

                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4  border-4-blur-md">
                    <h2 className="text-3xl font-bold  mb-4">Features That Make Magic Happen:</h2>
                    <ul className="list-disc list-inside font-light text-xl">
                        <li><strong>World Generator:</strong> Sketch story ideas and let AI generate immersive backdrops, cities, and landscapes.</li>
                        <li><strong>Story Plot Generator:</strong> Generate complex plots in minutes for novels or campaigns.</li>
                        <li><strong>Role-Playing Game Maker:</strong> Create solo adventures or multiplayer campaigns <span
                            className={'font-normal italic'}>(Coming Soon)</span>.
                        </li>
                        <li><strong>Interactive Fiction Studio:</strong> Build adventure stories with decision trees and alternate pathways.</li>
                    </ul>
                </section>

                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4  border-4-blur-md">
                    <h2 className="text-3xl font-bold mb-4">A Platform for Writers, Gamers, and Dreamers</h2>
                    <p className="text-xl font-light mb-4">Whether you're writing your next big fantasy book, building a solo RPG experience, or
                        leading a
                        multiplayer campaign, <strong>My Adventures</strong> is the key to unlocking your imagination.</p>
                </section>

                <section className="max-w-5xl bg-gray-900 bg-opacity-20 rounded-lg p-4  border-4-blur-md">
                    <h2 className="text-3xl font-bold  mb-4">Create and Share Your Stories</h2>
                    <p className="text-xl font-light mb-4">Invite friends to explore your creations, participate in quests, and take part in dynamic
                        adventures
                        shaped by your imagination.</p>
                </section>

                <Button
                    onClick={login} // Call login here
                    className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Start Your Adventure Now
                </Button>
            </div>
        </div>
    );

}

export default LandingPage;
