import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'flowbite-react';
import axios from "axios";
import {useSession} from "../../context/SessionContext";

function PaymentResultPage() {
    const { type } = useParams(); // Fetch the route parameter
    const navigate = useNavigate();
    const { session, setSession } = useSession();
    const [balance, setBalance] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (type === 'cancel') {
            navigate('/pricing/boosters');
        }
    }, [type, navigate]);

    const handleGoBack = async () => {
        navigate('/account'); // Redirect to account page
    };

    useEffect(() => {

        // Function to fetch account balance
        const fetchBalance = async () => {
            try {
                const balanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/account/balance`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                setBalance(balanceResponse.data);
                if (balanceResponse.data) {
                    setSession((prevSession) => ({
                        ...prevSession,
                        ink: { availableBalance: balanceResponse.data }
                    }));
                }
            } catch (err) {
                console.error('Error fetching balance:', err);
                setError('Failed to load account balance.');
            }
        };
        fetchBalance();
    }, [session.token]);

    // Display error state
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="flex justify-center items-start min-h-screen pt-10">
            <div className="text-center">
                {type === 'success' ? (
                    <>
                        <h2 className="text-2xl font-bold">Payment Successful!</h2>
                        <p className="mt-4">Thank you for your purchase. Your ink balance has been updated.</p>
                        <p className="mt-1 font-bold">Your balance is now: {balance}</p>
                    </>
                ) : (
                    <>
                        <h2 className="text-2xl font-bold">Payment Unsuccessful!</h2>
                        <p className="mt-4">Sorry, something went wrong trying to take payment!</p>
                    </>
                )}
                <div className="mt-8 flex justify-center">
                    <Button onClick={handleGoBack} color={'gray'}>
                        Return To My Account
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PaymentResultPage;
