import React, {useState, useEffect, useCallback, useRef, useContext} from 'react';
import {
    ReactFlow,
    useReactFlow,
    ReactFlowProvider,
    addEdge,
    Background,
    MiniMap,
    Controls,
    applyEdgeChanges,
    applyNodeChanges,
    Handle,
    Position,
    NodeResizer, NodeResizeControl,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import { v4 as uuidv4 } from 'uuid';
import DarkModeContext from "../../../../context/DarkModeContext";

function ResizeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="#ff0071"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ position: 'absolute', right: 5, bottom: 5 }}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="16 20 20 20 20 16" />
            <line x1="14" y1="14" x2="20" y2="20" />
            <polyline points="8 4 4 4 4 8" />
            <line x1="4" y1="4" x2="10" y2="10" />
        </svg>
    );
}

// Custom hook to manage group state
const useGroupState = (nodes, setNodes, edges, setEdges) => {

    const toggleGroup = useCallback(
        (groupId) => {
            console.log('toggleGroup: ', groupId);
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === groupId) {
                        const isExpanded = node.data.isExpanded;
                        return {
                            ...node,
                            data: { ...node.data, isExpanded: !isExpanded },
                        };
                    }
                    return node;
                })
            );
        },
        [setNodes]
    );

    return { toggleGroup };
};

const controlStyle = {
    background: 'transparent',
    border: 'none',
};

const nodeTypes = {
    start: ({ data }) => (
        <div className="p-2 px-3 text-2xl bg-blue-200 rounded drag-handle">
            <div className="text-gray-900">Story Start</div>
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-400"
            />
        </div>
    ),
    end: ({ data }) => (
        <div className="p-2 px-3 text-2xl bg-red-200 rounded drag-handle">
            <div className="text-gray-900">Story End</div>
            <Handle
                type="target"
                position="left"
                className="w-3 h-3 bg-gray-400"
            />
        </div>
    ),
    plotLine: ({data}) => (
        <div className="px-2 pt-0.5 text-sm bg-yellow-300 rounded-t drag-handle">
            <NodeResizeControl style={controlStyle} minWidth={100} minHeight={100}>
                <ResizeIcon />
            </NodeResizeControl>
            <Background id={'2'} bgColor={'#1f2937'} color={'#6b7280'}  size={0.4} gap={10}   />
            <div className="text-gray-900">Plot Line</div>
            <Handle
                type="target"
                position="left"
                className="w-3 h-3 bg-gray-400"
            />
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-400"
            />
        </div>
    ),
    plotElement: ({data}) => (
        <div className="px-2 py-1  text-sm bg-green-200 rounded drag-handle">
            <div className="text-gray-900">Plot Element</div>
            <Handle
                type="target"
                position="left"
                className="w-3 h-3 bg-gray-400"
            />
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-400"
            />
        </div>
    ),
    and: ({ data }) => (
        <div className="p-2 bg-yellow-100 rounded drag-handle">
            <div className="font-medium italic text-center text-gray-900">AND</div>
            <Handle
                type="target"
                position="left"
                id="a"
                className="w-3 h-3 bg-gray-400"
                style={{top: '25%'}}
            />
            <Handle
                type="target"
                position="left"
                id="b"
                className="w-3 h-3 bg-gray-400"
                style={{top: '75%'}}
            />
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-400"
            />
        </div>
    ),
    or: ({ data }) => (
        <div className="p-2 bg-yellow-100 rounded drag-handle">
            <div className="font-medium italic text-center text-gray-900">OR</div>
            <Handle
                type="target"
                position="left"
                id="a"
                className="w-3 h-3 bg-gray-400"
                style={{top: '25%'}}
            />
            <Handle
                type="target"
                position="left"
                id="b"
                className="w-3 h-3 bg-gray-400"
                style={{top: '75%'}}
            />
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-400"
            />
        </div>
    ),
    plotStage: ({data}) => {
        const isExpanded = data.isExpanded;

        return (
            <div className={`px-2 pt-0.5 text-sm rounded-t drag-handle bg-gray-900`} >
                <NodeResizeControl style={controlStyle} minWidth={100} minHeight={100}>
                    <ResizeIcon />
                </NodeResizeControl>
                <div className={`text-gray-300`}>
                    Plot Stage
                </div>
                <Background id={'3'} bgColor={'#374151'} color={'#030712'} size={0.4} gap={10}  />
                <Handle
                    type="target"
                    position="left"
                    className="w-3 h-3 bg-gray-400"
                />
                <Handle
                    type="source"
                    position="right"
                    className="w-3 h-3 bg-gray-400"
                />
            </div>
        );
    },
    setFlag: ({data}) => (
        <div className="px-2 py-1  text-sm bg-gray-300 rounded drag-handle">
            <div className="text-gray-900">Set Flag</div>
            <Handle
                type="target"
                position="left"
                className="w-3 h-3 bg-gray-700"
            />
        </div>
    ),
    getFlag: ({data}) => (
        <div className="px-2 py-1  text-sm bg-gray-300 rounded drag-handle">
            <div className="text-gray-900">Get Flag</div>
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-700"
            />
        </div>
    ),
    in: ({data}) => (
        <div className="px-2 py-1 text-sm bg-gray-300 rounded drag-handle">
            <div className="text-gray-900">In</div>
            <Handle
                type="source"
                position="right"
                className="w-3 h-3 bg-gray-700"
            />
        </div>
    ),
    out: ({data}) => (
        <div className="px-2 py-1  text-sm bg-gray-300 rounded drag-handle">
            <div className="text-gray-900">Out</div>
            <Handle
                type="target"
                position="left"
                className="w-3 h-3 bg-gray-700"
            />
        </div>
    ),
};

const initialNodes = [];
const initialEdges = [];

const Sidebar = ({ onDragStart }) => {
    const nodeList = [
        { type: 'start', label: 'Story Start' },
        { type: 'end', label: 'Story End' },
        { type: 'plotLine', label: 'Plot Line' },
        { type: 'plotStage', label: 'Plot Stage' },
        { type: 'plotElement', label: 'Plot Element' },
        { type: 'and', label: 'AND Gate' },
        { type: 'or', label: 'OR Gate' },
        { type: 'setFlag', label: 'Set Flag' },
        { type: 'getFlag', label: 'Get Flag' },
        { type: 'in', label: 'In' },
        { type: 'out', label: 'Out' },
    ];

    return (
        <div className="w-60 bg-gray-100 dark:bg-gray-900 p-4">
            <h3 className="text-lg font-bold mb-4">Toolbar</h3>
            {nodeList.map((node) => (
                <div
                    key={node.type}
                    className="p-2 mb-2 bg-gray-600 text-gray-300 rounded shadow cursor-grab"
                    onDragStart={(event) => onDragStart(event, node.type)}
                    draggable
                >
                    {node.label}
                </div>
            ))}
        </div>
    );
};

const FlowComponent = ({ nodes, edges, setNodes, setEdges, reactFlowWrapper }) => {
    const {
        screenToFlowPosition,
        zoomIn,
        zoomOut,
        getNodes,
    } = useReactFlow();

    const { darkMode } = useContext(DarkModeContext);
    const { toggleGroup } = useGroupState(nodes, setNodes, edges, setEdges);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const droppedNodeType = event.dataTransfer.getData('application/reactflow');
            if (!droppedNodeType) return;

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            // Get all nodes under the mouse position
            const nodesOver = getNodes().filter((node) => {
                const element = document.querySelector(`[data-id="${node.id}"]`);
                const bounds = element?.getBoundingClientRect();

                if (!bounds) return false;

                return (
                    event.clientX >= bounds.left &&
                    event.clientX <= bounds.right &&
                    event.clientY >= bounds.top &&
                    event.clientY <= bounds.bottom
                );
            });

            // Prioritize plotStage over plotLine if both are under the cursor
            //const parent = nodesOver.find((n) => n.type === 'plotStage') || nodesOver.find((n) => n.type === 'plotLine');
            const parentLine = nodesOver.find((n) => n.type === 'plotLine');
            const parentStage = nodesOver.find((n) => n.type === 'plotStage');
            const parent = parentStage || parentLine;

            // Enforce rules for placement
            if (parent) {
                const parentType = parent.type;

                // Rule: start, end, plotLine cannot have a parent
                if (['start', 'end', 'plotLine'].includes(droppedNodeType)) {
                    console.warn(`${droppedNodeType} cannot have a parent node.`);
                    return;
                }

                // Rule: plotStage must have a plotLine parent
                if (droppedNodeType === 'plotStage' && parentType !== 'plotLine') {
                    console.warn('plotStage must be placed inside a plotLine.');
                    return;
                }

                // Rule: plotElement must have a plotStage parent
                if (droppedNodeType === 'plotElement' && parentType !== 'plotStage') {
                    console.warn('plotElement must be placed inside a plotStage.');
                    return;
                }
            } else {
                // No parent node

                // Rule: in and out must always have a parent
                if (['in', 'out'].includes(droppedNodeType)) {
                    console.warn(`${droppedNodeType} must be placed inside a parent node.`);
                    return;
                }

                // Rule: plotStage must always have a parent (plotLine)
                if (droppedNodeType === 'plotStage') {
                    console.warn('plotStage must be placed inside a plotLine.');
                    return;
                }

                // Rule: plotElement cannot exist without a parent
                if (droppedNodeType === 'plotElement') {
                    console.warn('plotElement must be placed inside a plotStage.');
                    return;
                }

                // Rule: start, end, plotLine are allowed without a parent (standalone)
                if (!['start', 'end', 'plotLine', 'setFlag', 'getFlag', 'and', 'or'].includes(droppedNodeType)) {
                    console.warn(`${droppedNodeType} must be placed inside a parent node.`);
                    return;
                }
            }

            const placementPosition = parent && parent.type === 'plotLine'
                ? {
                    x: position.x - parentLine.position.x,
                    y: position.y - parentLine.position.y,
                }
                : parent && parent.type === 'plotStage'
                    ? {
                        x: position.x - parentLine.position.x - parentStage.position.x,
                        y: position.y - parentLine.position.y - parentStage.position.y,
                    }
                    : position;

            // Create and add the new node
            const newId = uuidv4();
            const newNode = {
                id: newId,
                type: droppedNodeType,
                position: placementPosition,
                data: {
                    id: newId,
                    label: `${droppedNodeType.charAt(0).toUpperCase() + droppedNodeType.slice(1)}`,
                },
                ...(parent
                    ? {
                        parentId: parent.id,
                        extent: 'parent', // Keep the node within the parent bounds
                    }
                    : {}),
            };

            setNodes((nds) => [...nds, newNode]);
        },
        [screenToFlowPosition, getNodes, setNodes]
    );


    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            colorMode={darkMode ? 'dark' : 'light'}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onDragOver={onDragOver}
            onDrop={onDrop}
            nodeTypes={nodeTypes}
            zoomOnScroll={true}
            zoomOnPinch={true}
            snapToGrid={true}
            snapGrid={[10, 10]}
            fitView
            selectNodesOnDrag={false}
            multiSelectionKeyCode={null}
        >
            <MiniMap />
            <Controls />
            <Background bgColor={ darkMode ? '#111827' : '#f3f4f6'} size={0.8} gap={20}  />
        </ReactFlow>
    );
};

const Test = () => {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const reactFlowWrapper = useRef(null);

    return (
        <div className="flex h-full">
            <Sidebar
                onDragStart={(event, nodeType) => {
                    event.dataTransfer.setData('application/reactflow', nodeType);
                    event.dataTransfer.effectAllowed = 'move';
                }}
            />
            <div className="flex-1 relative" ref={reactFlowWrapper}>
                <ReactFlowProvider>
                    <FlowComponent
                        nodes={nodes}
                        edges={edges}
                        setNodes={setNodes}
                        setEdges={setEdges}
                        reactFlowWrapper={reactFlowWrapper}
                    />
                </ReactFlowProvider>
            </div>
        </div>
    );
};

export default Test;
