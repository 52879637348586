import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import { useNavigate } from "react-router-dom";
import {useSession} from "../../context/SessionContext"; // For navigation

function LockscreenPage() {
    const { session, api } = useSession(); // Get session data from context
    const navigate = useNavigate(); // React Router hook for navigation

    useEffect(() => {
        // Check for an existing session
        if (session) {
            navigate("/home"); // Navigate to the home page if a session exists
        }
    }, [session, navigate, api]); // Dependencies include session and navigate


    return (
        <div className="relative w-full h-full overflow-hidden flex items-center justify-center">
            <Helmet>
                <title>Lockscreen - My Adventures</title>
                <meta
                    name="description"
                    content="You have been logged out of My Adventures. Please sign-in to continue."
                />
            </Helmet>
            {/* Background Layer */}
            <div
                className={
                    'absolute inset-0 bg-center bg-cover bg-no-repeat opacity-20 blur-sm'
                }
                style={{
                    backgroundImage: 'url(https://storage.googleapis.com/myadventures-images/lockscreen.webp)',
                }}
            />

            {/* Text Layer */}
            <h2 className="relative text-white text-xl md:text-2xl font-bold text-center">
                Please Sign-In to use My Adventures
            </h2>
        </div>
    );
}

export default LockscreenPage;