import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Utility function to robustly check if a string is valid JSON
const isValidJSON = (str) => {
    if (typeof str !== 'string') {
        return false; // Only strings can be valid JSON
    }

    // Trim whitespace and validate JSON structure
    const trimmedStr = str.trim();
    if (!trimmedStr.startsWith('{') && !trimmedStr.startsWith('[')) {
        return false; // Must start with a '{' or '[' to be JSON
    }

    // Try parsing the string into JSON
    try {
        JSON.parse(trimmedStr);
        return true; // Parsing succeeded: valid JSON
    } catch (error) {
        // Parsing failed: not valid JSON
        return false;
    }
};

const MarkdownRenderer = ({ content, darkMode }) => {
    // Fallback content if none is provided
    const safeContent = content || 'No content available.';

    return (
        <div className="content markdown">
            <ReactMarkdown
                className="prose dark:prose-dark"
                remarkPlugins={[remarkGfm]}
                components={{
                    h1: ({ node, ...props }) => (
                        <h1 style={{ color: darkMode ? '#aaaaaa' : '#444444' }} {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                        <h2 style={{ color: darkMode ? '#aaaaaa' : '#444444' }} {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                        <h3 style={{ color: darkMode ? '#aaaaaa' : '#444444' }} {...props} />
                    ),
                    strong: ({ node, ...props }) => (
                        <span className={'font-bold'} {...props} />
                    ),
                    p: ({ node, ...props }) => <p style={{ marginBottom: '1em' }} {...props} />,
                    code: ({ node, ...props }) => {
                        const rawContent = props.children || '';
                        const isJSON = isValidJSON(rawContent);

                        // Format JSON if applicable
                        const formattedText = isJSON
                            ? JSON.stringify(JSON.parse(rawContent), null, 2) // Pretty-format JSON
                            : rawContent;

                        return (
                            <div className={"bg-gray-100 dark:bg-gray-950 rounded-lg p-2 opacity-60"}>
                                <code
                                    className="font-roboto font-medium text-gray-800 dark:text-gray-300"
                                    style={{ whiteSpace: 'pre-wrap' }} // Preserve line breaks and spacing
                                    {...props}
                                >
                                    {formattedText}
                                </code>
                            </div>
                        );
                    },
                    img: ({ node, ...props }) => (
                        <img
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                marginTop: '1em',
                                marginBottom: '1em',
                            }}
                            {...props}
                        />
                    ),
                    blockquote: ({ node, ...props }) => (
                        <blockquote
                            className="border-l-4 border-gray-300 pl-4 italic text-gray-600 dark:text-gray-400"
                            {...props}
                        />
                    ),
                    ul: ({ node, ...props }) => (
                        <ul
                            className="list-disc list-inside"
                            style={{ marginLeft: '1em', paddingLeft: '1em' }}
                            {...props}
                        />
                    ),
                    ol: ({ node, ...props }) => (
                        <ol
                            className="list-decimal list-inside"
                            style={{ marginLeft: '1em', paddingLeft: '1em' }}
                            {...props}
                        />
                    ),
                    li: ({ node, ...props }) => (
                        <li style={{ marginBottom: '0.5em', paddingLeft: '0.5em' }} {...props} />
                    ),
                }}
            >
                {safeContent}
            </ReactMarkdown>
        </div>
    );
};

export default MarkdownRenderer;